import {motion} from "framer-motion";
import React from "react";
import PropTypes from "prop-types";

export const HoverTap = ({children, className, stiffness, damping}) => {
  return (
    <motion.div className={className}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.99 }}
                transition={{ type: "spring", stiffness: stiffness ?? 400, damping: damping ?? 10 }}>
      {children}
    </motion.div>
  );
}

export const HoverTapBtn = ({text}) => {
  return (
    <motion.div className="btn"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.99 }}
                transition={{ type: "spring", stiffness: 600, damping: 6 }}>
      {text}
    </motion.div>
  );
}

HoverTapBtn.propTypes = {
  text: PropTypes.string,
}

export default HoverTapBtn;
