import React, {useState} from "react";
import PropTypes from "prop-types";
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Layout from "../components/Layout";
import {HTMLContent} from "../components/Content";
import { motion } from "framer-motion"
import HoverTapBtn from "../components/HoverTapButton";

// eslint-disable-next-line
export const TeamPageTemplate = ({
  title,
  team
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="content dziki-team">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/team.jpg')`,
          filter: "contrast(.5)",
          backgroundPosition: "center"
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 has-text-centered"
          style={{
            color: "white",
            padding: "1rem",
          }}
        >
          {title}
        </h1>
      </div>
      <section className="section">
        <div className="container">
            <div className="tabs is-large">
              <ul>
              {
                team.blurbs.map(
                  (data, idx) => (<li key={`${idx}`} className={activeIndex === idx ? 'is-active' : null}>
                    <a onClick={() => setActiveIndex(idx)}>{data.buttonName}</a>
                  </li>)
                )
              }
              </ul>
            </div>
            {
              team.blurbs.map((data, idx) =>
                ( activeIndex === idx ?
                    <motion.div
                      initial={{
                        opacity: 0,
                        x: -200
                      }}
                      animate={{
                        opacity: 1,
                        x: 0
                      }}
                      exit={{
                        opacity: 0,
                        x: 200
                      }}
                      className="columns card m-0">
                      <div className="column is-8 is-flex is-flex-direction-column p-6">
                        <h3 className="has-text-weight-semibold is-size-4">
                          {data.name}
                        </h3>
                        <HTMLContent className="content" content={data.text} />
                        <div className="is-flex-grow-1"></div>

                          <Link to="/booking" className="is-align-self-center">
                            <HoverTapBtn text="Umów wizytę" />
                          </Link>

                      </div>
                      <div className="column image is-4 p-0">
                          <GatsbyImage
                            image={getImage(data.image) || data.image}
                            style={{
                              borderRadius: "0.25rem",
                              height: "100%"
                            }}
                            objectFit={"cover"}
                            alt=""
                            formats={["auto", "webp", "avif"]}
                            transformOptions={{fit: "cover"}}
                          />
                      </div>
                    </motion.div> : null
                )
              )
            }
        </div>
      </section>
    </div>
  );
};

TeamPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  team: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const TeamPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TeamPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        team={frontmatter.team}
      />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TeamPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        team {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(quality: 64, layout: CONSTRAINED, aspectRatio: 0.75, placeholder: BLURRED)
              }
            }
            text
            buttonName
            name
          }
        }
      }
    }
  }
`;
